<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-08 15:18:35
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-13 11:13:06
 * @FilePath: \official_website\src\mobile\pages\products\Product.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="product">
    <Header></Header>
    <div class="product-top">
      <p class="title animate__animated animate__fadeInDown">产品及解决方案</p>
    </div>
    <div class="product-content">
      <div
        class="product-solutions"
        v-for="(items, indexs) in productList"
        :key="indexs"
      >
        <div class="product-header">
          <h4 class="title">
            {{ items.title }}
          </h4>
        </div>
        <ul class="solution-box">
          <li
            class="solution-item"
            v-for="(item, index) in items.children"
            :key="index"
            @click="tapPages(item.path)"
          >
            <img class="solution-img" :src="item.src" />
            <div class="solution-text">{{ item.lable }}</div>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      productList: [
        {
          title: "解决方案",
          children: [
            {
              src: require("@/assets/image/product/solution3.png"),
              lable: "宫颈癌筛查解决方案",
              path: "/product/tbs",
              prodId: 29,
            },
            {
              src: require("@/assets/image/product/solution1.png"),
              lable: "DNA倍体癌症早筛解决方案",
              path: "/product/dna",
              prodId: 28,
            },
            {
              src: require("@/assets/image/product/solution4.png"),
              lable: "远程病理诊断解决方案",
              path: "/product/telepathology",
              prodId: 27,
            },
            {
              src: require("@/assets/image/product/solution2.png"),
              lable: "血液细胞形态诊断解决方案",
              path: "/product/blood",
              prodId: 26,
            },
          ],
        },
        {
          title: "细胞学早筛诊断试剂",
          children: [
            {
              src: require("@/assets/image/product/reagent-1.png"),
              lable: "液基细胞学TCT诊断试剂",
              path: "/product/tctReagent",
              prodId: 25,
            },
            {
              src: require("@/assets/image/product/reagent-2.png"),
              lable: "DNA定量分析诊断试剂",
              path: "/product/dnaReagent",
              prodId: 24,
            },
          ],
        },
        {
          title: "细胞学制片染色自动化解决方案",
          children: [
            {
              src: require("@/assets/image/product/film-1.png"),
              lable: "液基制片染色一体机",
              path: "/product/pelleter",
              prodId: 23,
            },
            {
              src: require("@/assets/image/product/film-2.png"),
              lable: "液基膜式制片机",
              path: "/product/film",
              prodId: 22,
            },
            // {
            //   src: require("@/assets/image/product/film-3.png"),
            //   lable: "液基甩片制片机",
            // },
            {
              src: require("@/assets/image/product/film-4.png"),
              lable: "全自动特殊染色机",
              path: "/product/special",
              prodId: 21,
            },
          ],
        },
        {
          title: "数字病理切片扫描解决方案",
          children: [
            {
              src: require("@/assets/image/product/digit-1.png"),
              lable: "扫描通量600片",
              path: "/product/gemini",
              prodId: 19,
            },
            {
              src: require("@/assets/image/product/digit-2.png"),
              lable: "扫描通量120片",
              path: "/product/gemini",
              prodId: 18,
            },
            {
              src: require("@/assets/image/product/digit-3.png"),
              lable: "扫描通量30片",
              path: "/product/gemini",
              prodId: 17,
            },
            {
              src: require("@/assets/image/product/digit-4.png"),
              lable: "扫描通量2片",
              path: "/product/gemini",
              prodId: 16,
            },
          ],
        },
        {
          title: "骨髓/血液细胞形态学AI扫描分析解决方案",
          children: [
            {
              src: require("@/assets/image/product/blood-1.png"),
              lable: "骨髓/血液细胞形态学AI扫描分析系统",
              path: "/product/marrow",
              prodId: 7,
            },
            {
              src: require("@/assets/image/product/blood-2.png"),
              lable: "全自动推片染色机",
              path: "/product/bloodPushing",
              prodId: 6,
            },
          ],
        },
        {
          title: "“吉米娜”人工智能辅助诊断系统",
          children: [
            {
              src: require("@/assets/image/product/diagnosis-1.png"),
              lable: "DNA倍体定量分析系统",
              path: "/product/dnaProd",
              prodId: 5,
            },
            {
              src: require("@/assets/image/product/diagnosis-2.png"),
              lable: "细胞学TCT人工智能辅助诊断",
              path: "/product/tctProd",
              prodId: 4,
            },
            {
              src: require("@/assets/image/product/diagnosis-3.png"),
              lable: "远程病理会诊系统",
              path: "/product/patholog",
              prodId: 3,
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    tapPages(path) {
      this.$router.push(path);
    },
  },
};
</script>
    
<style lang="scss">
#product {
  padding-top: 3.75rem;
  .product-top {
    position: relative;
    width: 100%;
    height: 8rem;
    background-image: url("../../../assets/image/product/header.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .title {
      position: absolute;
      left: 1.25rem;
      top: 20%;
      transform: translateY(-50%);
      color: #fff;
      font-size: 1.25rem;
      font-family: "SourceHanSansSC-Medium";
    }
  }
  .product-content {
    margin-bottom: 2rem;
    .product-solutions {
      .product-header {
        padding: 1.5rem 1.25rem;
        .line {
        }
        .title {
          position: relative;
          padding-top: 1.5rem;
          font-size: 1.5rem;
          // margin-bottom: 1.5rem;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 4.5rem;
            height: 0.625rem;
            background-color: #0b847b;
            -webkit-transition: width 0.5s ease-out 0.8s;
            transition: width 0.5s ease-out 0.8s;
          }
        }
        .description {
        }
      }
      .solution-box {
        padding: 0 1.25rem;
        .solution-item {
          padding: 1rem 0;
          .solution-img {
            width: 100%;
          }
          .solution-text {
            padding: 1rem 1.25rem;
            font-size: 1.2rem;
            font-family: "SourceHanSansSC-Medium";
            background-color: #fafafa;
          }
        }
      }
    }
  }

  .footer {
    background: #f5f5f5;
  }
}
</style>